.advantages-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(25);

  @include mediaBigDesktop {
    gap: big(25);
  }

  @include mediaTablet {
    gap: rem(10);
  }

  &__icon {
    width: rem(24);
    height: rem(24);

    user-select: none;
    pointer-events: none;

    @include mediaBigDesktop {
      width: big(24);
      height: big(24);
    }

    & img,
    & picture {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(8);
    }
  }

  &__title {
    width: 100%;

    color: var(--section-text-color-primary);
    font-weight: 500;
  }

  &__text {
    width: 100%;

    color: var(--section-text-color-secondary);
    font: var(--font-body-M);
  }
}
