@import '../../mixins';

.advantages {
  &__content {
    padding: rem(80) 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: rem(80);
    row-gap: rem(25);

    border-top: 1px solid var(--stroke-dark-secondary);
    border-bottom: 1px solid var(--stroke-dark-secondary);
    
    @include mediaDesktop {
      column-gap: rem(260);
      row-gap: rem(35);
    }

    @include mediaBigDesktop {
      padding: big(80) 0;
      column-gap: big(55);
      row-gap: big(25);

      border-top-width: big(1);
      border-bottom-width: big(1);
    }

    @include mediaLaptop {
      padding: rem(60) 0;
    }

    @include mediaTablet {
      padding: rem(50) 0;
      grid-template-columns: 1fr;
    }

    @include mediaMobile {
      padding: rem(35) 0;
      row-gap: rem(15)
    }
  }

  // States
  &_big {
    & .advantages {
      &__content {
        grid-template-columns: repeat(4, 1fr);

        @include mediaTablet {
          grid-template-columns: repeat(2, 1fr);
        }

        @include mediaMobile {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@import "advantages-card";
